<template>
  <div class="">
    <div class="flex justify-between items-center mb-6 border-b pb-6">
      <div>
        <h1 class="text-4xl">Create new staff</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <spinner :show="isLoading" :content="`Saving Content`" />
      <form
        v-if="!isLoading"
        class="needs-validation"
        @submit.prevent="onSubmit"
      >
        <div class="flex flex-wrap overflow-hidden">
          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                First Name
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.first_name"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('first_name'),
                }"
                required
                placeholder="Enter first name"
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Last Name
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.last_name"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('last_name'),
                }"
                required
                placeholder="Enter last name"
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Email
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="email"
                v-model="form.email"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('email'),
                }"
                required
                placeholder="Enter email"
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Contact Phone
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.contact_phone"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('contact_phone'),
                }"
                required
                placeholder="Enter contact phone"
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Branch
              </label>

              <select
                v-model="form.branch"
                :class="{
                  'is-invalid': form.errors.has('branch'),
                }"
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
              >
                <option selected disabled>Select Branch</option>
                <option
                  v-for="(branch, i) in branches"
                  :key="i"
                  :value="branch.id"
                >
                  {{ branch.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Jobe Title
              </label>
              <select
                v-if="form.job_title != 'other'"
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                v-model="form.job_title"
                :class="{
                  'is-invalid': form.errors.has('job_title'),
                }"
              >
                <option value="branch_manager">Branch Manager</option>
                <option value="teller">Teller</option>
                <option value="other">Other</option>
              </select>
              <input
                v-if="form.job_title == 'other'"
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.job_title_other"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('job_title'),
                }"
                required
                placeholder="Enter job title"
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Role
              </label>

              <select
                v-model="form.role"
                :class="{
                  'is-invalid': form.errors.has('role'),
                }"
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
              >
                <option selected disabled>Select Role</option>
                <option v-for="(role, i) in roles" :key="i" :value="role">
                  {{ role.display_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
          </div>
          <!-- SUBMIT BUTTON -->
          <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <button
              class="
                px-4
                py-2
                rounded
                text-white
                inline-block
                shadow-lg
                bg-blue-500
                hover:bg-blue-600
                focus:bg-blue-700
              "
              :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
              type="submit"
              :disabled="isLoading"
            >
              <span v-if="isLoading">Loading...</span>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../../mixins/checkPermissionExist";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",

  layout: "default",
  components: {
    // BranchSales,
    // HelloWorld
  },

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  created() {
    if (!this.checkPermissionExist(this.user.data, "staffs-create")) {
      this.$router.go(-1);
      return false;
    }
  },

  data: () => ({
    isLoading: false,
    branches: [],
    roles: [],
    form: new Form({
      first_name: "",
      last_name: "",
      email: "",
      contact_phone: "",
      branch: "",
      job_title: "",
      job_title_other: "",

      role: "",
    }),
  }),

  mounted() {
    this.loadBranches();
    this.loadRoles();
  },

  methods: {
    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        await this.form.post("/staff/store");

        this.isLoading = false;
        this.$router.push({ path: "/staffs" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    async loadBranches() {
      // Submit the form

      try {
        const { data } = await axios.get(`/get/branches`);

        this.branches = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    async loadRoles() {
      // Submit the form

      try {
        const { data } = await axios.get(`/roles`);

        this.roles = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },
  },
};
</script>
