var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_vm._m(0),_c('div',{staticClass:"flex flex-col"},[_c('spinner',{attrs:{"show":_vm.isLoading,"content":"Saving Content"}}),(!_vm.isLoading)?_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" First Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.first_name),expression:"form.first_name"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('first_name'),
              },attrs:{"name":"name","type":"text","required":"","placeholder":"Enter first name"},domProps:{"value":(_vm.form.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "first_name", $event.target.value)}}})])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" Last Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.last_name),expression:"form.last_name"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('last_name'),
              },attrs:{"name":"name","type":"text","required":"","placeholder":"Enter last name"},domProps:{"value":(_vm.form.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "last_name", $event.target.value)}}})])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" Email ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('email'),
              },attrs:{"name":"email","type":"text","required":"","placeholder":"Enter email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" Contact Phone ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contact_phone),expression:"form.contact_phone"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('contact_phone'),
              },attrs:{"name":"name","type":"text","required":"","placeholder":"Enter contact phone"},domProps:{"value":(_vm.form.contact_phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contact_phone", $event.target.value)}}})])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" Branch ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.branch),expression:"form.branch"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('branch'),
              },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "branch", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":""}},[_vm._v("Select Branch")]),_vm._l((_vm.branches),function(branch,i){return _c('option',{key:i,domProps:{"value":branch.id}},[_vm._v(" "+_vm._s(branch.name)+" ")])})],2)])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" Jobe Title ")]),(_vm.form.job_title != 'other')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.job_title),expression:"form.job_title"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('job_title'),
              },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "job_title", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"branch_manager"}},[_vm._v("Branch Manager")]),_c('option',{attrs:{"value":"teller"}},[_vm._v("Teller")]),_c('option',{attrs:{"value":"other"}},[_vm._v("Other")])]):_vm._e(),(_vm.form.job_title == 'other')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.job_title_other),expression:"form.job_title_other"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('job_title'),
              },attrs:{"name":"name","type":"text","required":"","placeholder":"Enter job title"},domProps:{"value":(_vm.form.job_title_other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "job_title_other", $event.target.value)}}}):_vm._e()])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-gray-700 text-sm font-normal mb-2",attrs:{"for":"username"}},[_vm._v(" Role ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.role),expression:"form.role"}],staticClass:"\n                shadow\n                appearance-none\n                border\n                rounded\n                w-full\n                py-2\n                px-3\n                text-gray-700\n                leading-tight\n                focus:outline-none focus:shadow-outline\n              ",class:{
                'is-invalid': _vm.form.errors.has('role'),
              },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":""}},[_vm._v("Select Role")]),_vm._l((_vm.roles),function(role,i){return _c('option',{key:i,domProps:{"value":role}},[_vm._v(" "+_vm._s(role.display_name)+" ")])})],2)])]),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"}),_c('div',{staticClass:"w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2"},[_c('button',{staticClass:"\n              px-4\n              py-2\n              rounded\n              text-white\n              inline-block\n              shadow-lg\n              bg-blue-500\n              hover:bg-blue-600\n              focus:bg-blue-700\n            ",class:{ 'opacity-50 cursor-not-allowed': _vm.isLoading },attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('span',[_vm._v("Loading...")]):_c('span',[_vm._v("Save")])])])])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between items-center mb-6 border-b pb-6"},[_c('div',[_c('h1',{staticClass:"text-4xl"},[_vm._v("Create new staff")])])])}]

export { render, staticRenderFns }